import Header from '../Header.vue'
import Footer from '../Footer.vue'
import Page from '../../components/page.vue'
import NoData from '../../components/NoData.vue'
import CheckInService from '../../api/CheckInService.js'
export default {
	name: 'MechanismList',
	data() {
		return {
			mechanismType: 0,
			searchTypeList: [
				'A',
				'B',
				'C',
				'D',
				'E',
				'F',
				'G',
				'H',
				'I',
				'J',
				'K',
				'L',
				'M',
				'N',
				'O',
				'P',
				'Q',
				'R',
				'S',
				'T',
				'U',
				'V',
				'W',
				'X',
				'Y',
				'Z',
			],
			typeIndex: -1,
			mechanismList: [], //列表
			pageIndex: 1,
			pageSize: 10,
			pageCount: 0,
			hotList: [],
		}
	},
	methods: {
		GetExpertList() {
			//专家
			var that = this
			CheckInService.GetExpertList(
				that.pageIndex,
				that.pageSize,
				that.typeIndex == -1 ? '' : that.searchTypeList[that.typeIndex]
			).then((res) => {
				console.log(res)
				if (res.Tag == 1) {
					that.mechanismList = res.Result
					that.pageCount = res.TotalCount
				}
			})
		},
		GetMechanismList() {
			//机构
			var that = this
			CheckInService.GetMechanismList(
				that.pageIndex,
				that.pageSize,
				that.mechanismType,
				that.typeIndex == -1 ? '' : that.searchTypeList[that.typeIndex]
			).then((res) => {
				if (res.Tag == 1) {
					that.mechanismList = res.Result
					that.pageCount = res.TotalCount
				}
			})
		},
		GetTopExpertList() {
			//专家
			var that = this
			CheckInService.GetTopExpertList(5).then((res) => {
				if (res.Tag == 1) {
					that.hotList = res.Result
				}
			})
		},
		GetTopMechanismList() {
			//机构
			var that = this
			CheckInService.GetTopMechanismList(that.mechanismType, 5).then(
				(res) => {
					if (res.Tag == 1) {
						that.hotList = res.Result
					}
				}
			)
		},
		changeType(index) {
			this.typeIndex = index
			this.pageIndex = 1
			this.pageCount = 0
			if (this.mechanismType == 2) {
				//专家
				this.GetExpertList()
			} else {
				//机构
				this.GetMechanismList()
			}
		},
		pageChange(page) {
			this.pageIndex = page
			if (this.mechanismType == 2) {
				//专家
				this.GetExpertList()
			} else {
				//机构
				this.GetMechanismList()
			}
			window.scrollTo(0, 0)
		},
	},
	mounted() {
		this.mechanismType = this.$route.query.type
		if (this.mechanismType == 2) {
			//专家
			this.GetExpertList()
			this.GetTopExpertList()
		} else {
			//机构
			this.GetMechanismList()
			this.GetTopMechanismList()
		}
	},
	components: {
		Header: Header,
		Footer: Footer,
		Page: Page,
		NoData: NoData,
	},
}
